import React from 'react';
import team1 from '../assets/images/team/bello.jpg';
import team2 from '../assets/images/team/wasiu.jpg';
import team3 from '../assets/images/team/admin.jpg';
import team4 from '../assets/images/team/cmac.jpeg';

const MgmtTeam = () => {
  return (
    <section className='team-one team-one__become-teacher'>
      <div className='container'>
        <div className='block-title text-center'>
          <h2 className='block-title__title'>Meet our Top Management Team</h2>
        </div>
        <div className='row'>
          {/* <div className='col-xl-3 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={team1} alt='' />
              </div>
              <div className='team-one__content'>
                <h2 className='team-one__name'>Alhaji Isa Bello Sali</h2>
                <p className='team-one__text'>The Board Chairman</p>
              </div>
            </div>
          </div> */}
          <div className='col-xl-3 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={team2} alt='' />
              </div>
              <div className='team-one__content'>
                <h2 className='team-one__name'>Prof. Wasiu ‘Lanre Adeyemo, FAS</h2>
                <p className='team-one__text'>The Chief Medical Director</p>
              </div>
            </div>
          </div>
          <div className='col-xl-3 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={team3} alt='' />
              </div>
              <div className='team-one__content'>
                <h2 className='team-one__name'><br />Ms. Omolola Olubukunola Fakeye (JP)</h2>
                <p className='team-one__text'>The Director of Administration</p>
              </div>
            </div>
          </div>
          <div className='col-xl-3 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={team4} alt='' />
              </div>
              <div className='team-one__content'>
                <h2 className='team-one__name'>Dr. Ayodeji Oluwole</h2>
                <p className='team-one__text'>
                  The Chairman, Medical Advisory Committees
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MgmtTeam;
