import React from 'react';
import Layout from "../../components/Layout";
import NavOne from "../../components/NavOne";
import PageHeader from "../../components/PageHeader";
import MGMT from "../../components/MGMT";
import Footer from "../../components/Footer";

const AboutPage = () => {
    return (
        <Layout pageTitle="Top Management | LUTH">
            <NavOne />
            <PageHeader title="" />
            <MGMT />            
            <Footer />
        </Layout>
    );
};

export default AboutPage;
